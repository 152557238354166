import { FormattedNumber } from "react-intl";

import type { Voucher } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { Text } from "~/components/ui/text";
import { useSite } from "~/contexts";

const CouponCodeSummary = ({ appliedVoucher }: { appliedVoucher: Voucher }) => {
  const siteContext = useSite();
  return (
    <div className="flex justify-between">
      <Text className="text-sm font-normal text-success">
        {appliedVoucher.code}
      </Text>
      <Text className="text-sm font-normal text-success">
        <FormattedNumber
          value={appliedVoucher.value ? appliedVoucher.value * -1 : 0}
          style="currency"
          currency={siteContext?.locale?.preferredCurrency || "$"}
        />
      </Text>
    </div>
  );
};

export default CouponCodeSummary;
