import type { IconProps } from "../../types";

const LowStockIcon = ({ size = 24, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_14458_2538"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_14458_2538)">
        <path
          d="M12 4C12.9833 4 13.9292 4.1375 14.8375 4.4125C15.7458 4.6875 16.6 5.1 17.4 5.65L15.5 6.85C14.95 6.56667 14.3792 6.35417 13.7875 6.2125C13.1958 6.07083 12.6 6 12 6C9.78333 6 7.89583 6.77917 6.3375 8.3375C4.77917 9.89583 4 11.7833 4 14C4 14.7 4.09583 15.3917 4.2875 16.075C4.47917 16.7583 4.75 17.4 5.1 18H18.9C19.2833 17.3667 19.5625 16.7083 19.7375 16.025C19.9125 15.3417 20 14.6333 20 13.9C20 13.3 19.9292 12.7167 19.7875 12.15C19.6458 11.5833 19.4333 11.0333 19.15 10.5L20.35 8.6C20.85 9.38333 21.2458 10.2167 21.5375 11.1C21.8292 11.9833 21.9833 12.9 22 13.85C22.0167 14.8 21.9083 15.7083 21.675 16.575C21.4417 17.4417 21.1 18.2667 20.65 19.05C20.4667 19.35 20.2167 19.5833 19.9 19.75C19.5833 19.9167 19.25 20 18.9 20H5.1C4.75 20 4.41667 19.9167 4.1 19.75C3.78333 19.5833 3.53333 19.35 3.35 19.05C2.91667 18.3 2.58333 17.5042 2.35 16.6625C2.11667 15.8208 2 14.9333 2 14C2 12.6167 2.2625 11.3208 2.7875 10.1125C3.3125 8.90417 4.02917 7.84583 4.9375 6.9375C5.84583 6.02917 6.90833 5.3125 8.125 4.7875C9.34167 4.2625 10.6333 4 12 4Z"
          fill="#FFD800"
        />
        <path
          d="M20.4405 8.80054C19.5642 6.75588 16.8447 5.30978 16.1462 4.96918H16.1033C15.1588 5.27001 13.712 6.06457 15.4804 6.8362C17.2487 7.60783 18.6687 9.58694 19.1577 10.48H19.7468C20.2701 10.6018 21.1415 10.4363 20.4405 8.80054Z"
          fill="#FFD800"
        />
        <path
          d="M6.68259 8.21039L6.05284 5.04016L9.76377 3.65159L11.6153 6.08212L11.6105 6.46446L6.68259 8.21039Z"
          fill="white"
        />
        <path
          d="M6.68259 8.21039L6.05284 5.04016L9.76377 3.65159L11.6153 6.08212L11.6105 6.46446L6.68259 8.21039Z"
          fill="white"
        />
      </g>
      <path
        d="M10.0062 14.9091L8.37108 4.87048L13.6611 13.3782L10.0062 14.9091Z"
        fill="#FFD800"
      />
      <circle
        cx="11.8924"
        cy="14.2989"
        r="1.99463"
        transform="rotate(-65.543 11.8924 14.2989)"
        fill="#FFD800"
      />
    </svg>
  );
};

export default LowStockIcon;
