import type { Cart } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { isGiftCard } from "~/lib/utils/giftcard";

const giftCardCheckout = (basket?: Cart) => {
  return basket?.entries?.[0]?.product
    ? isGiftCard(basket?.entries?.[0]?.product)
    : false;
};

export default giftCardCheckout;
